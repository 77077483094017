<template>
  <div class="side-menu next">
    <div class="row info-wrapper mob-hide">
      <div class="col-12">
        <p class="eoi">EOI Booking</p>
        <h2 class="page-heading">Shubham Artesia</h2>
        <p class="align-data">Ghatkopar</p>
      </div>
    </div>
  <div class="typology">
    <h3 class="headline border-bottom-0 pb-0">Select Variant</h3>
    <ul class="list-group custom-inline-box">
      <li class="list-group-item list-group-item-action" v-for="floor in realVariants">
        <div class="custom-control custom-radio ">
          <input type="radio" :id="floor.id" name="floorSelector" class="custom-control-input" @click="selectFloor(floor)" :checked="selectedTypology !== null && floor.id === selectedTypology.id">
          <label class="custom-control-label" :for="floor.id">
            <div class="floor-section">
              <div class="floor-data">
                <div class="floor-value">
                  {{floor.name}} &nbsp;&nbsp;&nbsp;
                  <div class="d-inline-flex align-items-center">
                    <div class="plot-size mr-3"><span>&bull;&nbsp;RERA</span> {{floor.carpet_area}} sqft</div>
                    <div class="plot-size"><span>&bull;&nbsp;MOFA</span> {{floor.sale_area}} sqft</div>
                  </div>
                </div>
              </div>
            </div> 
            <span class="divider"></span>
          </label>
        </div>
      </li>
    </ul>
    <div class="side-action">
      <div class="submit-button-section">
        <DownArrow id="down-arrow" v-if="isMobile" v-show="isMobile && isDownVisible"/>
        <span class="d-block submit-button-card" tabindex="0" data-toggle="tooltip">
          <!-- <span v-if="isMobile" class="submit-message">Select Variant and Continue</span>  -->
          <button :disabled="!(this.selectedUnit && this.selectedUnit.id)" type="button" class="btn btn-block btn-primary"  @click="goToPlans">Continue</button>
          <span class="form-error-text" v-if="isInvalid">Please select a variant</span>
          <!-- <router-link to="/Unitplan" class="btn btn-primary btn-block" >Proceed</router-link> -->
      </span>
      </div>
    </div>
  </div>
  <!-- <div class="disclaimer"><span>Disclaimer:</span> This submission expresses your interest in our property but does not constitute a confirmed booking. The final plot allocation will occur only after completing the formal property booking process.</div> -->
  <div class="row back-section">
    <button @click="$router.push({name:'Inventory',query:{...$route.query}})" type="button" class="prevButton">
      <img src="@/assets/images/back-arrow.svg" alt="Back" height="16" width="16">
      Back</button>
    </div>
    <div class="col-12 powered-by">Powered by <span class="powered-by-ppistol"> Enrichr</span></div>

</div>
</template>
<script>
import { masterJson } from '@/assets/scripts/utility.js'
export default {
  components:{
    DownArrow: ()=> import('@/components/Common/DownArrow.vue')
  },
  data(){
    return {
      isInvalid:false,
      isDownVisible:true,
      realVariants: [],
      selectedConfig: null,
      selectedWing: null,
    }
  },
  watch: {
    selectedTypology(newVal) {
        this.selectedConfig = newVal.configuration;
        this.selectedWing = newVal.wing;
        if(this.selectedConfig, this.selectedWing){
          this.getVariants();
        }
    }
  },
  methods: {
    getVariants(){
      this.$axios.get('inventories/variants', {
        params: {
          "building": `Wing ${this.selectedTypology.wing}`,
          "variant_id": this.selectedTypology.id
        }
      }).then(res => {
        if(res.data.variants){
          this.realVariants = res.data.variants;
        }
      })
    },
    selectFloor(floor) {
      this.isInvalid=false;
      this.$store.dispatch('setFloor', floor);
      this.$store.dispatch('setUnit', floor);
    },
    goToPlans() {
      if(!this.selectedTypology || !this.selectedTypology.id){
        this.isInvalid = true;
        return;
      }
      this.$store.dispatch('setLoading',true);
      this.$router.push({ name: 'Unit', query:{...this.$route.query} })
    },
    handleScroll(event){
      if(window.innerHeight + window.scrollY < document.body.offsetHeight){
        this.isDownVisible = true;
      }else{
        this.isDownVisible = false;
      }
    }
  },
  computed: {
    variants() {
      return masterJson.Variants;
    },
    selectedUnit() {
      return this.$store.getters.selectedUnit;
    },
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    },
    isMobile(){
      return window && window.innerWidth<=991;
    }
  },
  mounted(){
    if(this.isMobile){
      window.addEventListener('scroll',this.handleScroll,{passive:true});
    }
    this.$store.dispatch("setUnit", {});
    this.$store.dispatch('setLoading',false);
    if(this.selectedTypology && this.selectedTypology.id){
      this.getVariants();
    }
  },
  unmounted(){
    if(this.isMobile){
      window.removeEventListener('scroll',this.handleScroll,{passive:true});
    }
  }
}
</script>
<style scoped>
h3 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  padding: 16px;
}

.info-wrapper p.eoi{
    font-weight: 600;
    margin-bottom: 12px;
  }
.side-menu{
   border: none;
}

.disclaimer{
  color: rgba(0, 0, 0, 0.50);
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 20px;
}
.disclaimer span{
  color: #000;
  font-weight: 500;
}
 .info-wrapper{
  margin-bottom: 20px;
  padding-right: 15px;
  border-radius: 10px;
  display: flex;
 }

  .info-wrapper h2{
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
     .info-wrapper .page-heading {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 4px;
  }

  .info-wrapper p {
    font-size: 13px;
    color: #000000;
    line-height: 16px;
    text-transform: uppercase;
    opacity: 0.5;
    padding-left: 0px;
    padding-bottom: 0px;
    margin-bottom: 14px;
  }
.info-wrapper .align-data {
    font-size: 13px;
    color: #000000;
    line-height: 16px;
    opacity: 0.5;
    text-transform: none;
    padding-left: 0px;
    padding-top: 0;
    margin-bottom: 0;
 }

.side-menu .typology {
  border-radius: 10px;
  border: 1px solid rgba(2, 103, 193, 0.20);
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
  padding: 4px;
  padding-bottom: 16px;
  position: relative;
}

.side-menu .typology .list-group {
  flex-direction: column;
  max-height: 50vh;
  overflow: auto;
}

.side-menu .typology .list-group {
	/* scrollbar-width: thin; */
	box-shadow: 4px 0 0 rgba(255, 255, 255, 1);
}

.side-menu .typology .list-group::-webkit-scrollbar {
	width: 8px;
}

.side-menu .typology .list-group::-webkit-scrollbar-track {
	background: transparent
}

.side-menu .typology .list-group::-webkit-scrollbar-thumb {
	background: #b0b0b0;
	border-radius: 10px;
	width: 8px;
}

.side-menu .typology .list-group::-webkit-scrollbar-thumb:hover {
	background: #5d5e5f;
}

.side-menu .typology .list-group-item {
  padding: 0;
  border: none;
}

.list-group{
  display: flex;
}

.list-group-item-action{
  width: 100%;
}
.back-section{
  padding-left: 15px;
}

.custom-control {
  margin-right: 0;
}

.custom-control-label {
  padding: 10px 15px 10px 36px;
  width: 100%;
}

.custom-control-label .divider {
  width: 5px;
  height: 100%;
  border-radius: 0px 4px 4px 0px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: 50% 50% no-repeat;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMicgaGVpZ2h0PScxMicgdmlld0JveD0nLTQgLTQgOCA4Jz48Y2lyY2xlIHI9JzMnIGZpbGw9JyMxRUI0RDInLz48L3N2Zz4=)
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before{
  background-color:#ffffff; 
  outline: none;
}

.custom-control-input:not(:disabled):not(:checked):active ~ .custom-control-label::before,
.custom-control-input:not(:disabled):not(:checked):focus ~ .custom-control-label::before{
  border-color:#000000;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color:#1EB4D2;
  background-color:#ffffff;
}


.custom-radio .custom-control-input:checked ~ .custom-control-label .price-range-title{
  color: rgba(0, 0, 0, 0.5);
}

.custom-control-input:checked ~ .custom-control-label .divider{
  display: none;
}

.typology .custom-control-label::before,
.typology .custom-control-label::after {
  top: 13px;
  box-shadow: none;
  left: 15px;
}

.floor-section{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.floor-value{
  display: block;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 4px;
}


.plot-size span, .villa-size span, .price-range-title{
  color: rgba(0, 0, 0, 0.50);
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  margin-right: 2px;
}

.plot-size, .villa-size{
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}

.price-range-title{
  margin-bottom: 6px;
  display: block;

}

.price-value{
  color: #000;
  text-align: right;
  font-size: 15px;
  font-weight: 600;
  display: block;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  top: 13px;
  left: 15px;
  margin: 2px;
}
.side-action {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  gap:15px;
  width: 100%;
  margin-top: 12px;
  background: #fff;
  border-radius: 10px;
}
.prevButton {
  color: rgba(0, 0, 0, 0.5);
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  margin-top: 30px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: 0.3s;
  padding: 0;
}
.prevButton:hover{
  gap: 10px;
}
.side-action  .btn-primary {
  background: #1EB4D2;
  border-radius: 4px;
  padding: 16px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  border-color:#1EB4D2;
  box-shadow:none;
}

.side-action  .btn-primary:hover{
  background: #1EB4D2;
  border-color:#1EB4D2;
}
.side-action  .btn-primary:focus,
.side-action  .btn-primary:active{
  background: #1EB4D2;
  border-color:#1EB4D2;
}
.side-action .btn-primary:disabled{
  opacity: 0.65;
}
.side-action  p {
  font-weight: 400;
  font-size: 9px;
  color: #082D57;
  opacity: 0.5;
  text-align: center;
}
.powered-by{
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #00000080;
  text-align: center;
  padding-top: 20px;
  margin-top: auto;
}
.powered-by .powered-by-ppistol{
  color: #000000;
}

.submit-button-section{
  text-align: center;
}

.form-error-text{
  color: red;
}

.submit-message{
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: inline-block;
  margin-bottom: 16px;
}

@media (max-width:991px) {

  .side-menu .typology .list-group{
    max-height: none;
  }

  .disclaimer{
    margin-left: 16px;
    margin-right: 16px;
  }
  .mob-hide{
    display: none;
  }
  .powered-by{
    display: none;
  }
  .prevButton{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
 
  .side-menu .typology{
    border: none;
  }
  .back-section{
    margin:0;
    padding-left: 0;
  }

  .headline{
    border: none;
    padding-left: 20px;
    padding-right: 20px;
  }

  .side-menu .typology .list-group-item{
    padding-left: 4px;
    padding-right: 4px;
  }

  .submit-button-section{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index:1003;
    width: 100%;
    
  }
  .submit-button-card{
    padding: 16px;
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(4, 153, 183, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  }

}
</style>